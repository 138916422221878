<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="px-3 pt-3 mb-7" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar color="secondary white--text text-h5">
                        Create a new task
                    </v-toolbar>    
                    <div class="pa-3">
                        <v-form>

                            <v-text-field ref="name" class="mt-5" outlined dense v-model="form.name" label="Name" :rules="rules">
                            </v-text-field>
                            <v-autocomplete ref="priority" :rules="rules" dense outlined v-model="form.priority" label="Priority" :items="priorityOption">
                            </v-autocomplete>
                            <v-autocomplete :rules="rules" ref="category" dense outlined  v-model="form.category" label="Category" :items="categoryOption">
                            </v-autocomplete>

                            <v-text-field v-model="this.form.deadline" label="Deadline" dense outlined @click="openDeadlineCalendar">

                            </v-text-field>
                            <v-dialog @click:outside="resetDeadline" v-model="isDeadline" width="auto" height="auto">
                                <div class="d-flex justify-center">
                                    <v-card class="pa-3">
                                        <p>
                                            Task deadline :
                                        </p>
                                        <div>
                                            <v-date-picker v-model="form.deadline" :landscape="true" :reactive="true">

                                            </v-date-picker>
                                        </div>
                                        <div name="footer">
                                            <div class="d-flex justify-end my-3">
                                                <v-btn color="primary" plain @click=resetDeadline>
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="primary" @click="confirmDeadline">
                                                    Confirm
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </div>
                            </v-dialog>

                        
                            <mavon-editor
                            style="z-index:0;height:auto"
                            :rules="rules"
                            defaultOpen = "edit"
                            :toolbarsFlag = "false"
                            language="en"
                            v-model="form.action"
                            placeholder="Action required...">
                            </mavon-editor>

                            <v-autocomplete :rules="rules" v-model="form.pic" item-text="staff_name" class="mt-5" return-object :items="picOption" multiple small-chips deletable-chips 
                            ref="pic"
                            solid outlined rv-model="form.pic" label="This task is for..." 
                            :menu-props="{bottom:true,offsetY:true}">
                            </v-autocomplete>
                            <v-checkbox class="pt-0 mt-0" v-model="form.notify" label="Do you want to send the notification to the staff assigned ?">

                            </v-checkbox>
                        
                            <div class="d-flex justify-end mt-3">
                                <v-btn color="primary" plain @click="redirectBack">
                                    Cancel
                                </v-btn>
                                <v-btn color="primary" @click="submit" :loading="api.isLoading" >
                                    Submit
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import mavonEditor from 'mavon-editor';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    "mavon-editor": mavonEditor.mavonEditor,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rules:[
            cannotEmpty=>!!cannotEmpty||'This field is required',  
        ],
        isDeadline:false,
        valid:false,
        isPending:false,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        form:{
            name:null,
            category:null,
            action:null,
            notify:null,
            pic:null,
            deadline:null,
        },
        picOption:null,
        categoryOption:[
            'Follow-up','Activation','Cancellation','Renewal','Check payment request'
        ],
        priorityOption:[
            'Low','Medium','Urgent'
        ]
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getCompanyPIC"){
                this.picOption = resp.data.pic;
            }
            if(resp.class=='saveTask'){

                this.api.isSuccesful = true;
                this.api.success = "Succesfully assigned a new task"
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompaniesPICApi = this.fetchCompaniesPIC();
            this.$api.fetch(fetchCompaniesPICApi);
        },
        fetchCompaniesPIC(){
            let companyId = this.$router.history.current.params.id; 
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/company/"+companyId;
            return tempApi;
        },  
        validateInput(){
            if(this.form.name==null){
                this.$refs.name.focus();
                return false;
            }
            if(this.form.category==null){
                this.$refs.category.focus();
                return false;
            }
            if(this.form.priority==null){
                this.$refs.priority.focus();
                return false;
            }
            if(this.form.action==null){
                this.$refs.action.focus();
            }
            if(this.form.pic==null){
                this.$refs.pic.focus();
            }
            return true;
            // this.isPending = true;
        },
        submit()
        {
            this.valid = this.validateInput();
            if(this.valid){
                let createApi = this.create();
                this.$api.fetch(createApi);
            }
            // this.isPending = false;
            
        },
        redirectBack(){
          this.$router.go(-1);  
        },
        openDeadlineCalendar(){
            this.isDeadline=true;
        },
        cancelSubmit(){
            this.isPending = true;
        },
        resetDeadline(){
            this.form.deadline=null;
            this.isDeadline = false;
        },
        confirmDeadline(){
            this.isDeadline = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task";
            let formData = new FormData;
            formData.append('companyId',this.$router.history.current.params.id);
            formData.append('userId',this.$store.getters.getUserId);
            this.form.pic.forEach(pic => {
                formData.append('pic[]',pic.pic_id);
            });
            formData.append('name',this.form.name);
            formData.append('category',this.form.category);
            formData.append('action',this.form.action);
            formData.append('priority',this.form.priority);
            if(this.form.deadline!=null){
                formData.append('deadline_at',this.form.deadline);
            }
            if(this.form.notify==true){
                formData.append('notify',true);
            }
            tempApi.params = formData;
            return tempApi;
            // formData.append('userId',)
        },
    }
}
</script>